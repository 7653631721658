export const getCurrentDateWithHoursMinutes=(incomeDate)=>{

  const date =new Date(incomeDate)
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  // Формирование строки с датой и временем
  const formattedDate = day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;

  return formattedDate;
}
